export default {
  namespaced: true,
  state: {
    countries: []
  },
  getters: {
    allCountries: state => state.countries
  },
  mutations: {
    SET_COUNTRIES(state, val) {
      state.countries = val
    },
  },
  actions: {},
}